import { Card, CardOwner, Hint } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { coreBus } from '@/core/core-bus';
import { CardAutoMoveBaseController } from '@/core/card-auto-move.base.controller';

export class AutoMoveController extends CardAutoMoveBaseController {
    protected doBestMove(card: Card) {
        if (card.owner != CardOwner.tableau) {
            return;
        }

        const tableauTops = cardsQuery.getTopTableauCards();

        // first try to meld by rank and suit
        for (let i = 0; i < tableauTops.length; i++) {
            const top = tableauTops[i];
            if (top.rank == card.rank + 1 && top.suit == card.suit) {
                coreBus.meldCardCmd$.next({
                    card,
                    destCard: top,
                });
                return;
            }
        }

        // try to meld by rank only
        for (let i = 0; i < tableauTops.length; i++) {
            const top = tableauTops[i];
            if (top.rank == card.rank + 1) {
                coreBus.meldCardCmd$.next({
                    card,
                    destCard: top,
                });
                return;
            }
        }

        // try to move sequence to empty tableau
        const topCards = cardsQuery.getTopTableauCards();
        const allTabs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        const emptyTabs = allTabs.filter((t) => !topCards.find((c) => c.ownerIndex == t));
        if (emptyTabs.length > 0) {
            coreBus.moveCardToEmptyTableauCmd$.next({
                card,
                tableauIndex: emptyTabs[0],
            });
            return;
        }

        coreBus.shakeCardCmd$.next({
            card,
        });
    }

    protected isAutoFinishPossible() {
        return false;
    }

    protected doAutoFinish(done: () => void) {
        done();
    }

    protected generateHints() {
        const tabTops = cardsQuery.getTopTableauCards();
        const cards = cardsQuery
            .getAllByOwner(CardOwner.tableau)
            .filter((x) => x.dragEnabled && x.isFaceUp);

        // hints for tableau
        const tabHints = cards.flatMap((c) => {
            return tabTops
                .filter((tc) => tc.ownerIndex != c.ownerIndex && tc.rank == c.rank + 1)
                .map(
                    (tc) =>
                        ({
                            card1Id: c.id,
                            card2Id: tc.id,
                            priority: tc.suit == c.suit ? 0 : 1,
                        } as Hint)
                );
        });

        console.log(tabHints);

        return tabHints;
    }
}
