import { bus } from '@/games/spider/bus';
import { Card, CardOwner } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { coreUtil } from '@/core/core-util';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { moveHistory } from '@/core/move-history';
import { gameService } from '@/state/game/game.service';
import { coreBus } from '@/core/core-bus';
import { CardMoveBaseController } from '@/core/card-move-base.controller';
import { cardSound } from '@/core/sound';

export class MoveController extends CardMoveBaseController {
    constructor() {
        super({
            enableTableauAutoAdjust: true,
            meldCard: {
                validate: (cmd) => cmd.card.rank + 1 == cmd.destCard.rank,
                after: (cmd) => {
                    setTimeout(() => {
                        bus.cardMeldedEvent$.next({
                            tableauIndex: cmd.destCard.ownerIndex,
                        });
                    }, 300);
                },
            },
            moveCardToEmptyTableauCmd: {
                validate: (cmd) => {
                    const top = cardsQuery.getTopByOwnerAndIndex(
                        CardOwner.tableau,
                        cmd.tableauIndex
                    );
                    return !top;
                },
            },
        });

        this.subscribeTo(bus.moveCardToOriginCmd$, (ev) => {
            this.moveCardToOrigin(ev.card);
        });
        this.subscribeTo(bus.stockClickEvent$, () => {
            this.dealNextBatch();
            coreBus.gameMoveCompletedEvent$.next();
        });
        this.subscribeTo(bus.cardMeldedEvent$, (ev) => {
            this.completeSeriesValid(ev.tableauIndex);
            coreBus.gameMoveCompletedEvent$.next();
        });
    }

    private completeSeriesValid(tableauIndex: number) {
        const cards = cardsQuery.getOrderedByOwnerAndIndex(CardOwner.tableau, tableauIndex);
        if (cards.length < 13) {
            return;
        }

        // get top 13
        const cards13 = cards.slice(cards.length - 13, cards.length);

        // check all cards are face up
        if (cards13.filter((c) => !c.isFaceUp).length > 0) {
            return;
        }

        // validate series
        for (let i = 0; i < 12; i++) {
            const c = cards13[i];
            const cc = cards13[i + 1];
            if (c.rank - 1 != cc.rank || c.suit != cc.suit) {
                return;
            }
        }

        // get empty foundation
        const foundations = [1, 2, 3, 4, 5, 6, 7, 8];
        let emptyFound = 0;
        for (let i = 0; i < foundations.length; i++) {
            if (!cardsQuery.getTopByOwnerAndIndex(CardOwner.foundation, foundations[i])) {
                emptyFound = foundations[i];
                break;
            }
        }

        moveHistory.startMove();

        // update cards
        const updates = cards13.map((c, i) => {
            moveHistory.addState(c);
            return {
                ...c,
                owner: CardOwner.foundation,
                ownerIndex: emptyFound,
                dragEnabled: false,
                order: i + 1 + emptyFound,
            };
        });
        cardsService.upsertMany(updates);

        // flip top card if exists
        const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, tableauIndex);
        if (top) {
            cardsService.update(top.id, {
                isFaceUp: true,
            });
            moveHistory.addState(top);
        }

        gameService.increaseMoveCounter();
        moveHistory.endMove();

        // move all cards
        cards13.forEach((card) => {
            coreBus.cardMoveCmd$.next({
                cardId: card.id,
                duration: 300,
            });
        });

        cardSound.playScore();
    }

    private async dealNextBatch() {
        if (!this.canMakeMove()) {
            return;
        }

        const cards5 = cardsQuery.getAllByOwnerAndIndex(CardOwner.stock, 5);
        if (cards5.length == 0) {
            return;
        }

        moveHistory.startMove();

        // we need to shift all stocks to right
        const cards4Updates = cardsQuery.getAllByOwnerAndIndex(CardOwner.stock, 4).map((c) => {
            moveHistory.addState(c);
            return {
                ...c,
                owner: CardOwner.stock,
                ownerIndex: 5,
            };
        });
        const cards3Updates = cardsQuery.getAllByOwnerAndIndex(CardOwner.stock, 3).map((c) => {
            moveHistory.addState(c);
            return {
                ...c,
                owner: CardOwner.stock,
                ownerIndex: 4,
            };
        });
        const cards2Updates = cardsQuery.getAllByOwnerAndIndex(CardOwner.stock, 2).map((c) => {
            moveHistory.addState(c);
            return {
                ...c,
                owner: CardOwner.stock,
                ownerIndex: 3,
            };
        });
        const cards1Updates = cardsQuery.getAllByOwnerAndIndex(CardOwner.stock, 1).map((c) => {
            moveHistory.addState(c);
            return {
                ...c,
                owner: CardOwner.stock,
                ownerIndex: 2,
            };
        });

        // cards5 update deal cards
        const cards5Updates = cards5.map((c, i) => {
            moveHistory.addState(c);
            const ownerIndex = 10 - i;
            const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, ownerIndex);
            const order = top ? top.order + 1 : 1;
            return {
                ...c,
                isFaceUp: true,
                owner: CardOwner.tableau,
                ownerIndex,
                order,
            };
        });

        const shiftUpdates = cards4Updates
            .concat(cards3Updates)
            .concat(cards2Updates)
            .concat(cards1Updates);

        const allUpdates = cards5Updates.concat(shiftUpdates);

        cardsService.upsertMany(allUpdates);

        gameService.increaseMoveCounter();
        moveHistory.endMove();

        const sub = interval(60)
            .pipe(take(10))
            .subscribe((i) => {
                coreBus.cardMoveCmd$.next({
                    cardId: cards5Updates[9 - i].id,
                    duration: 700,
                });
            });
        this.subscription.add(sub);

        cardSound.playShuffle();

        await coreUtil.delay(800);

        shiftUpdates.forEach((card) => {
            coreBus.cardMoveCmd$.next({
                cardId: card.id,
                duration: 300,
            });
        });

        // iterate over all tableaus and see if anything is completed
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((t) => {
            this.completeSeriesValid(t);
        });
    }

    private moveCardToOrigin(card: Card) {
        coreBus.cardMoveCmd$.next({
            cardId: card.id,
            duration: 300,
        });
        // move cards below as well
        let nextOrder = card.order + 1;
        while (nextOrder > 0) {
            const belowCard = cardsQuery.getCardByOwnerAndIndexAndOrder(
                card.owner,
                card.ownerIndex,
                nextOrder
            );
            if (belowCard) {
                coreBus.cardMoveCmd$.next({
                    cardId: belowCard.id,
                    duration: 300,
                });
                nextOrder++;
            } else {
                nextOrder = 0;
            }
        }
    }
}
