import { display } from '@/games/spider/display';
import { cardsQuery } from '@/state/cards/cards.query';
import { CardOwner } from '@/core/models';
import { CardDragBaseController } from '@/core/card-drag.base.controller';

export class DragController extends CardDragBaseController {
    constructor() {
        super({
            tableauCardIntersect: {
                canPutCardOnTopOf: (card, dest) => card.rank + 1 == dest.rank,
            },
            tableauIntersect: {
                frames: () => [
                    { position: display.tableau1Position, index: 1 },
                    { position: display.tableau2Position, index: 2 },
                    { position: display.tableau3Position, index: 3 },
                    { position: display.tableau4Position, index: 4 },
                    { position: display.tableau5Position, index: 5 },
                    { position: display.tableau6Position, index: 6 },
                    { position: display.tableau7Position, index: 7 },
                    { position: display.tableau8Position, index: 8 },
                    { position: display.tableau9Position, index: 9 },
                    { position: display.tableau10Position, index: 10 },
                ],
                validate: (card, index) => {
                    const top = cardsQuery.getTopByOwnerAndIndex(CardOwner.tableau, index);
                    return !top;
                },
            },
        });
    }
}
