import { gameService } from '@/state/game/game.service';
import { Card, CardOwner, GameStatus, Suit } from '@/core/models';
import { appQuery } from '@/state/app.query';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { gameQuery } from '@/state/game/game.query';
import { coreBus } from '@/core/core-bus';
import { moveHistory } from '@/core/move-history';
import { BaseGameController } from '@/core/base-game.controller';
import { range } from 'lodash';

export class GameController extends BaseGameController {
    constructor() {
        super();
        this.subscribeTo(coreBus.gameMoveCompletedEvent$, () => {
            // we only start the game after user made first move
            if (gameQuery.getValue().gameStatus == GameStatus.dealCompleted) {
                gameService.setGameStatus(GameStatus.running);
            }
            this.enableDisableDragForAllCards();
        });
        this.initStage();
    }

    private initStage() {
        const numberOfSuits = gameQuery.getValue().numberOfSuits;
        console.log('---init suits', numberOfSuits);
        let suits: Suit[];
        if (numberOfSuits == 1) {
            suits = [
                Suit.Spade,
                Suit.Spade,
                Suit.Spade,
                Suit.Spade,
                Suit.Spade,
                Suit.Spade,
                Suit.Spade,
                Suit.Spade,
            ];
        } else if (numberOfSuits == 2) {
            suits = [
                Suit.Spade,
                Suit.Spade,
                Suit.Spade,
                Suit.Spade,
                Suit.Heart,
                Suit.Heart,
                Suit.Heart,
                Suit.Heart,
            ];
        } else {
            suits = [
                Suit.Spade,
                Suit.Spade,
                Suit.Heart,
                Suit.Heart,
                Suit.Club,
                Suit.Club,
                Suit.Diamond,
                Suit.Diamond,
            ];
        }
        const cards = suits.flatMap((s, i) => {
            return range(1, 14).map(
                (r) =>
                    ({
                        id: `${r}${s}${i}`,
                        name: `${r}${s}`,
                        suit: s,
                        rank: r,
                        order: 0,
                        dragEnabled: false,
                        isFaceUp: false,
                        owner: CardOwner.none,
                    } as Card)
            );
        });
        cardsService.init(cards);
    }

    protected initGame(): void {
        cardsService.resetAllCards();
        moveHistory.reset();
    }

    protected isGameCompleted(): boolean {
        const cards = cardsQuery.getAll();
        const cardsInFoundation = cards.filter((c) => c.owner == CardOwner.foundation);
        return cardsInFoundation.length == 104;
    }

    private enableDisableDragForAllCards() {
        const updates: Card[] = [];

        // func to process tableau
        const processTableau = (tabIndex: number) => {
            const cards = cardsQuery
                .getOrderedByOwnerAndIndex(CardOwner.tableau, tabIndex)
                .filter((c) => c.isFaceUp);
            if (cards.length == 0) {
                return;
            }

            // top card always enabled
            updates.push({
                ...cards[cards.length - 1],
                dragEnabled: true,
            });

            let isSequenceDone = false;
            for (let i = cards.length - 2; i >= 0; i--) {
                const c = cards[i];
                const cp = cards[i + 1];
                const isSequence = c.rank == cp.rank + 1;
                const isSameSuit = c.suit == cp.suit;
                if (isSequence && !isSequenceDone && isSameSuit) {
                    updates.push({
                        ...c,
                        dragEnabled: true,
                    });
                } else {
                    isSequenceDone = true;
                    updates.push({
                        ...c,
                        dragEnabled: false,
                    });
                }
            }
        };

        processTableau(1);
        processTableau(2);
        processTableau(3);
        processTableau(4);
        processTableau(5);
        processTableau(6);
        processTableau(7);
        processTableau(8);
        processTableau(9);
        processTableau(10);

        cardsService.upsertMany(updates);
    }
}
