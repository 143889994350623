
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { controllers } from '@/games/spider/controllers/controllers';
import Frames from '@/games/spider/views/Frames.vue';
import CardGameStage from '@/components/CardGameStage.vue';
import { appQuery } from '@/state/app.query';
import { gameService } from '@/state/game/game.service';
import { display } from '@/games/spider/display';

export default defineComponent({
    components: {
        Frames,
        CardGameStage,
    },

    setup() {
        // get number suits and init cards
        const route = useRoute();
        const routeName = route.name;
        const parts = routeName ? routeName.toString().split('-') : [];
        const numberOfSuits = parts[1] ? parseInt(parts[1], 10) : 4;
        gameService.setNumberOfSuits(numberOfSuits);

        const controllersInst = computed(() => controllers);

        const displayInst = computed(() => display);

        const game = computed(() => appQuery.getActiveGame());

        const hideDailyChallenge = computed(() => {
            return game.value == 'spider4';
        });

        return {
            controllersInst,
            hideDailyChallenge,
            displayInst,
        };
    },
});
