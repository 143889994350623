import { Controller } from '@/core/controller';
import { GameController } from '@/games/spider/controllers/game.constroller';
import { DealerController } from '@/games/spider/controllers/dealer.controller';
import { DragController } from '@/games/spider/controllers/drag.controller';
import { MoveController } from '@/games/spider/controllers/move.controller';
import { AutoMoveController } from '@/games/spider/controllers/auto-move.controller';

export class Controllers {
    private controllers: Controller[] = [];

    init() {
        this.controllers.push(new GameController());
        this.controllers.push(new DealerController());
        this.controllers.push(new DragController());
        this.controllers.push(new MoveController());
        this.controllers.push(new AutoMoveController());
    }

    destroy() {
        this.controllers.forEach((c) => c.destroy());
    }
}

export const controllers = new Controllers();
