
import { defineComponent, ref } from 'vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { gameQuery } from '@/state/game/game.query';
import { display } from '@/games/spider/display';
import { bus } from '@/games/spider/bus';
import { recomputable } from '@/composable/recomputable';

export default defineComponent({
    setup() {
        const recompute = ref(0);

        const getFrameStyle = (x: number, y: number) => {
            return {
                top: `${y}px`,
                left: `${x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
                borderRadius: `${display.cardSize.width * 0.06}px`,
            };
        };

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const tableau1Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau1Position.x, display.tableau1Position.y);
        });

        const tableau2Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau2Position.x, display.tableau2Position.y);
        });

        const tableau3Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau3Position.x, display.tableau3Position.y);
        });

        const tableau4Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau4Position.x, display.tableau4Position.y);
        });

        const tableau5Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau5Position.x, display.tableau5Position.y);
        });

        const tableau6Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau6Position.x, display.tableau6Position.y);
        });

        const tableau7Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau7Position.x, display.tableau7Position.y);
        });

        const tableau8Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau8Position.x, display.tableau8Position.y);
        });

        const tableau9Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau9Position.x, display.tableau9Position.y);
        });

        const tableau10Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau10Position.x, display.tableau10Position.y);
        });

        const stockOverlayStyle = recomputable(recompute, () => {
            const x = display.stock1Position.x;
            const width = display.stock5Position.x - x + display.cardSize.width;
            return {
                top: `${display.stock1Position.y}px`,
                left: `${x}px`,
                width: `${width}px`,
                height: `${display.cardSize.height}px`,
            };
        });

        const stockClick = () => {
            bus.stockClickEvent$.next();
        };

        return {
            tableau1Style,
            tableau2Style,
            tableau3Style,
            tableau4Style,
            tableau5Style,
            tableau6Style,
            tableau7Style,
            tableau8Style,
            tableau9Style,
            tableau10Style,
            stockOverlayStyle,
            stockClick,
        };
    },
});
