import { Subject } from 'rxjs';
import { Card } from '@/core/models';

class Bus {
    moveCardToOriginCmd$ = new Subject<MoveCardToOriginCmd>();

    stockClickEvent$ = new Subject();

    cardMeldedEvent$ = new Subject<CardMeldedEvent>();
}

export type MoveCardToOriginCmd = {
    card: Card;
};

export type CardMeldedEvent = {
    tableauIndex: number;
};

export const bus = new Bus();
