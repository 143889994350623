import { gameQuery } from '@/state/game/game.query';
import { CardOwner, GameStatus } from '@/core/models';
import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { coreBus } from '@/core/core-bus';
import { CardDealerBaseController } from '@/core/card-dealer.base.controller';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 54);
        this.subscribeTo(gameQuery.gameStatus$, (status) => {
            if (status == GameStatus.dealCompleted) {
                this.setStockPiles();
            }
        });
    }

    private setStockPiles() {
        const cards = cardsQuery.getOrderedByOwner(CardOwner.none);
        if (cards.length != 50) {
            throw new Error('should be 50 cards left');
        }
        const updates = cards.map((c, i) => {
            let ownerIndex = 1;
            if (i >= 10 && i < 20) {
                ownerIndex = 2;
            } else if (i >= 20 && i < 30) {
                ownerIndex = 3;
            } else if (i >= 30 && i < 40) {
                ownerIndex = 4;
            } else if (i >= 40 && i < 50) {
                ownerIndex = 5;
            }
            return {
                ...c,
                owner: CardOwner.stock,
                ownerIndex,
                order: i + 1,
            };
        });
        cardsService.upsertMany(updates);
        cards.forEach((card) => {
            coreBus.cardMoveCmd$.next({
                duration: 0,
                cardId: card.id,
            });
        });
    }

    protected dealCard(i: number) {
        const card = cardsQuery.getTopByOwner(CardOwner.none);
        if (!card) {
            throw new Error('no card in stock');
        }

        // tableau 1
        if (i <= 6) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 1,
                order: i,
                dragEnabled: i == 6,
                isFaceUp: i == 6,
            });
        }

        // tableau 2
        if (i >= 7 && i <= 12) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i - 6,
                dragEnabled: i == 12,
                isFaceUp: i == 12,
            });
        }

        // tableau 3
        if (i >= 13 && i <= 18) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i - 12,
                dragEnabled: i == 18,
                isFaceUp: i == 18,
            });
        }

        // tableau 4
        if (i >= 19 && i <= 24) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i - 18,
                dragEnabled: i == 24,
                isFaceUp: i == 24,
            });
        }

        // tableau 5
        if (i >= 25 && i <= 29) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i - 24,
                dragEnabled: i == 29,
                isFaceUp: i == 29,
            });
        }

        // tableau 6
        if (i >= 30 && i <= 34) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i - 29,
                dragEnabled: i == 34,
                isFaceUp: i == 34,
            });
        }

        // tableau 7
        if (i >= 35 && i <= 39) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 7,
                order: i - 34,
                dragEnabled: i == 39,
                isFaceUp: i == 39,
            });
        }

        // tableau 8
        if (i >= 40 && i <= 44) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 8,
                order: i - 39,
                dragEnabled: i == 44,
                isFaceUp: i == 44,
            });
        }

        // tableau 9
        if (i >= 45 && i <= 49) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 9,
                order: i - 44,
                dragEnabled: i == 49,
                isFaceUp: i == 49,
            });
        }

        // tableau 10
        if (i >= 50 && i <= 54) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 10,
                order: i - 49,
                dragEnabled: i == 54,
                isFaceUp: i == 54,
            });
        }

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
